import configAxios, { configAxiosLambda } from "../../axios.config";

type quizProgress = Array<{
  questionId: string;
  selectedAnswer: string;
}>;

type codingProgress = Array<{
  questionId: string;
  code: string;
  language: language_values;
}>;

type quizResult = {
  score: Array<{
    questionId: string;
    score: number;
    questionNo: number;
    option: string;
  }>;
  review: Array<any>;
  examId: string;
  questions_Attempted: Array<string>;
  finalScore: number;
};

async function updateQuizProgress(quizProgress: any, quizResult: any, hybridExamId: string, timeRemaining: number) {
  return configAxiosLambda(localStorage.getItem("token") || "")
    .post(`/progress/update-quiz-progress`, {
      examId: hybridExamId,
      quizProgress,
      quizResult,
      timeRemaining,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

type examProgressResponse = {
  examId: string;
  userId: string;
  codingProgress: codingProgress;
  codingResult: Array<IScoringArray>;
  isCodingCompleted: boolean;
  isQuizCompleted: boolean;
  quizProgress: quizProgress;
  quizResult: quizResult;
  timeRemaining: number;
};

function getExamProgress(examId: string): Promise<{ examProgress: examProgressResponse }> {
  return new Promise((resolve, reject) => {
    try {
      configAxiosLambda(localStorage.getItem("token") || "")
        .get<{ examProgress: examProgressResponse }>(`/progress/get-exam-progress/${examId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error, "Hey caught the error");
          reject(error);
        });
    } catch (err) {
      reject(err);
    }
  });
}

function completeQuizExam(examId: string, timeRemaining: number) {
  return new Promise((resolve, reject) => {
    configAxiosLambda(localStorage.getItem("token") || "")
      .post(`/progress/complete-quiz`, {
        examId,
        timeRemaining,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

function deleteProgress(examId: string) {
  return new Promise((resolve, reject) => {
    configAxiosLambda(localStorage.getItem("token") || "")
      .delete(`/progress/delete-exam-progress/${examId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export { updateQuizProgress, getExamProgress, completeQuizExam, deleteProgress };
