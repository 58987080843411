import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./styles/textbasedexams.module.css";
import configAxios from "../../axios.config";
import { AuthContext } from "../../Context/AuthContext";
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";
import logo from "../../Assets/img/cantilever-logo.png";
import 'suneditor/dist/css/suneditor.min.css';
import SunEditorComp from 'suneditor-react';

const NotificationPopup = ({ title, message, type }) => {
    Store.addNotification({
        title,
        message,
        type,
        // background: "pink",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 3000,
            onScreen: true,
        },
    });
};


const TextBasedExams = ({ expiryTimestamp, hidenavbar }) => {
    const queryParams = useRef(new URLSearchParams(window.location.search));

    const { examId } = useParams<{ examId: string }>();
    const [examstage, setExamstage] = useState(0);
    const [examData, setExamData] = useState<{
        _id: string;
        category: string;
        description: string;
        examName: string;
        locked: boolean;
        textBasedQuestions: Array<{
            _id: string;
            question: string;
        }>
    } | null>(null);
    const [questionsArray, setQuestionsArray] = useState<Array<{
        _id: string;
        question: string;
        answer: string;
    }>>();
    const [index, setIndex] = useState<number>(0);
    const [userName, setuserName] = useState<string>("");
    const [userDP, setuserDP] = useState("");
    const authContextData = useContext(AuthContext);
    const { token } = authContextData;
    const [showpopup, setshowpopup] = useState(false);
    const [numberofTabs, setnumberofTabs] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [currQues, setcurrQues] = useState(1);
    // const questionWeight = useRef(1);
    // const displayResult = useRef<boolean>(true);
    const questionEditorRef = useRef<HTMLDivElement>(null);
    const [divSize, setDivSize] = useState({ width: 0 });

    useEffect(() => {
        // save it on local storage
        localStorage.setItem("currQues", currQues.toString());
    }, [currQues]);


    const history = useHistory();

    useEffect(() => {
        hidenavbar(true);
        return () => {
            hidenavbar(false);
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setnumberofTabs(count => count + 1);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        window.addEventListener("unload", handleEndConcert);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
            window.removeEventListener("unload", handleEndConcert);
            handleEndConcert();
        };
    }, []);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
        // setprompt(true);
    };

    window.onload = function () {
        var reloading = sessionStorage.getItem("reloading");
        if (reloading) {
            sessionStorage.removeItem("reloading");
            // myFunction();
            // submithandler();
        }
    };

    const handleEndConcert = async () => {
        sessionStorage.setItem("reloading", "true");
        // console.log(e);
    };


    useEffect(() => {
        if (!history) return;
        const controller = new AbortController();

        configAxios(localStorage.getItem("token"))
            .get("/getProfile", {
                signal: controller.signal
            })
            .then(({ data }) => {
                setuserDP(data?.user?.profile_pic);
                setuserName(data?.user?.firstName + " " + data?.user?.lastName);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 401:
                        history.push("/login");
                        NotificationPopup({
                            title: "Unauthorized",
                            message: "Please Login to attempt the exam",
                            type: "danger"
                        });
                        return;
                    default:
                        return;
                }
            });

        return () => controller.abort()
    }, [history])

    useEffect(() => {
        if (!history) return;
        const controller = new AbortController();

        configAxios(localStorage.getItem("token"))
            .get<{
                _id: string;
                category: string;
                description: string;
                examName: string;
                locked: boolean;
                textBasedQuestions: Array<{
                    _id: string;
                    question: string;
                }>
            }>(`/txbq/get-text-based-exam/${examId}`, {
                signal: controller.signal
            })
            .then(({ data }) => {
                if (data.locked) {
                    history.push('/');
                    return;
                }
                setExamData(data);

                let modifiedData = data.textBasedQuestions.map(val => ({ ...val, answer: "" }))
                setQuestionsArray(modifiedData);
            });

        return () => controller.abort();
    }, [examId, history]);

    useEffect(() => {
        const rectWidth = questionEditorRef.current?.getBoundingClientRect();

        rectWidth && setDivSize({ width: rectWidth.width });
    }, [])

    const submithandler = () => {
        if (!questionsArray) return;
        setSpinner(true);
        let answer = questionsArray.map(val => ({
            questionId: val._id,
            answer: val.answer
        }));
        const submitData = {
            answer,
        };
        configAxios(localStorage.getItem("token"))
            .post(`/txbq/submit-text-based-result/${examId}`, submitData)
            .then(() => {
                history.push('/exam-submission-successful')
            })
            .catch((err) => alert(JSON.stringify(err.response.data)))
            .finally(() => setSpinner(false));
    };

    const questions = questionsArray ? questionsArray[index] : undefined;
    const length = questionsArray ? questionsArray.length : 0;

    const changeHandler = (val: string) => {
        if (val === "<p><br></p>") {
            return;
        }
        setQuestionsArray((prev) => {
            if (!prev) return prev;

            let prev_cpy: Array<{
                _id: string;
                question: string;
                answer: string;
            }> = JSON.parse(JSON.stringify(prev));
            const currQuesCount = parseInt(localStorage.getItem("currQues") || "1");
            prev_cpy[currQuesCount - 1].answer = val;
            return prev_cpy;
        });
    };





    let maxLenOfEachQuestion = Math.floor((divSize.width / 100) * 35);

    if (examstage === 0) {
        return (
            <>
                <div className="d-grid place-content-center"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-5 instructionPageLeft">
                            <img src={logo} className="instructionPageLogo" alt="logo" />
                            <div className="instructionPageTitle">Paragraph Exam Instructions</div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="instructionPageSubTitle">
                                        <i className="fas fa-clock"></i>Test Duration
                                    </div>
                                    <div className="instructionPageSubText">{queryParams.current.get("duration")} mins</div>
                                </div>
                                <div className="col-6">
                                    <div className="instructionPageSubTitle">
                                        <i className="fas fa-file-alt"></i>No. of questions
                                    </div>
                                    <div className="instructionPageSubText">{queryParams.current.get("questions")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 instructionPageRight">
                            <div className="instructionPageRightTitle">Instructions:</div>
                            <ol className="instructionPageRightList">
                                <li>This is a timed test. Please make sure you are not interrupted during the test, as the timer cant be paused once started.</li>
                                <li>Please ensure you have a stable internet connection.</li>
                                <li>Please make sure that you do not reload the page.</li>
                                <li>Results willl be mailed to you after the test is finished</li>
                            </ol>
                            <button className="instructionPageBtn" onClick={() => setExamstage(1)}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div id="main">
            {showpopup === true && (
                <div
                    className={styles.popup_submission}
                    style={{ backdropFilter: "blur(10px)" }}
                >
                    <div className="d-flex flex-column">
                        <span className={styles.popup_text}>
                            Are you sure that you want to submit your Exam?
                        </span>
                        <div className="d-flex flex-row justify-content-start">
                            <button
                                onClick={() => {
                                    setshowpopup(false);
                                }}
                                className={styles.submission_quiz_button}
                                style={{
                                    background: "transparent",
                                    border: "1px solid white",
                                    color: "#fff",
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    submithandler();
                                }}
                                className={styles.submission_quiz_button}
                            >
                                {spinner ? (
                                    <div className="spinner-border spinner-border-sm"></div>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
                <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
                    {token != null ? (
                        <>
                            <div className={styles.quizPageNavbar}>
                                <img src={logo} className={styles.quizPageLogo} alt="logo" />
                                <div className={styles.quizPageNavbarRight}>
                                    <div className={styles.quizPageProfileDetail}>
                                        <img src={userDP} className={styles.quizPageProfilePic} alt="profile" />
                                        <span> {userName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.quizQuestionPage}>

                                <div className="mb-0">
                                    <div className={styles.quizQuestionPageLeft}>
                                        <div className="row" style={{ flexGrow: 1, overflowY: "auto" }}>
                                            <div className="col-12 mb-0">
                                                <div className={styles.quizQuestionPageQuestionNum}>
                                                    Question No. {currQues}
                                                </div>
                                                <hr />
                                                <div ref={questionEditorRef} className={questions && questions.question.length > maxLenOfEachQuestion ? styles.questionEditorWrapper : `${styles.questionEditorWrapper} ${styles.leftRight}`} >

                                                    <div className={questions && questions.question.length > maxLenOfEachQuestion ? styles.left : `${styles.left} ${styles.fullLengthLeft}`}>
                                                        <div className={styles.quizQuestionPageQuestion}>
                                                            {questions && questions.question}
                                                        </div>
                                                    </div>
                                                    <div className={questions && questions.question.length > maxLenOfEachQuestion ? `${styles.right} ${styles.getFullLen}` : `${styles.right} ${styles.fullLengthRight}`}>  {/*change div wrapper */}

                                                        {questions && <SunEditorComp
                                                            lang="en"
                                                            width="100%"
                                                            height="inherit"

                                                            autoFocus={true}
                                                            onResizeEditor={(height, prevHeight) => {
                                                                console.log({
                                                                    height,
                                                                    prevHeight
                                                                })
                                                            }}
                                                            onChange={changeHandler}
                                                            setContents={questions.answer}
                                                            hideToolbar={false}
                                                            onPaste={(e, cleanData, maxCharCount) => {
                                                                console.log(e, cleanData, maxCharCount);
                                                            }}
                                                            setOptions={{
                                                                height: "100%",
                                                                minHeight: "250px",
                                                                resizingBar: false,

                                                                buttonList: [
                                                                    [
                                                                        "undo",
                                                                        "redo",
                                                                        "fontSize",
                                                                        "bold",
                                                                        "underline",
                                                                        "italic",
                                                                        "subscript",
                                                                        "superscript",
                                                                        "hiliteColor",
                                                                        "outdent",
                                                                        "indent",
                                                                        "horizontalRule",
                                                                        "list",
                                                                    ],
                                                                ],
                                                            }}
                                                        />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 d-sm-flex justify-content-between" style={{
                                                marginBottom: 0
                                            }}>
                                                <div className="rightside">
                                                    {index === 0 ? null : (
                                                        <button
                                                            onClick={(e) => {
                                                                if (index === 0) {
                                                                } else {
                                                                    setIndex((prev) => prev - 1);
                                                                    setcurrQues((prev) => prev - 1);
                                                                }
                                                            }}
                                                            className={styles.quizPagePrevBtn}
                                                        >
                                                            <i className="fas fa-arrow-left"></i> Prev
                                                        </button>
                                                    )}
                                                    {index === length - 1 ? null : (
                                                        <button
                                                            onClick={(e) => {
                                                                if (index === length - 1) {
                                                                } else {
                                                                    setIndex(index + 1);
                                                                    setcurrQues((prev) => prev + 1);
                                                                }
                                                            }}
                                                            className={styles.quizPageNextBtn}
                                                        >
                                                            Next <i className="fas fa-arrow-right"></i>
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="leftside">
                                                    <button
                                                        className={styles.quizPageExamSubmitBtn}
                                                        onClick={() => {
                                                            setshowpopup(true);
                                                        }}
                                                    >
                                                        Submit Exam
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (
                        history.push("/")
                    )}
                </div>
            </div>
        </div>
    );
};

export default TextBasedExams;