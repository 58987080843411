import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "../../Assets/css/codinginstruction.css";
import logo from "../../Assets/img/cantilever-logo.png";
import { BASE_URL } from "../../env";

const QuizsInstruction = (props) => {
  const { id } = useParams();
  const [quizTimer, setquizTimer] = useState("");
  const [quizQuestion, setquizQuestions] = useState("");
  const [quizInstruction, setquizInstructions] = useState([]);

  useEffect(() => {
    fetch(BASE_URL + "/getparticularexamQuiz/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setquizTimer(data?.exam?.Quizexam?.timer);
        if (data.exam.Quizexam.randomQuestionLength) {
          setquizQuestions(data?.exam?.Quizexam.randomQuestionLength);
        } else {
          setquizQuestions(data?.exam?.Quizexam.questions?.length);
        }
        setquizInstructions(data?.exam?.QuizInstruction);
      });
  }, [id]);
  return (
    <>
      <div className="d-grid place-content-center"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 instructionPageLeft">
            <img src={logo} className="instructionPageLogo" alt="logo" />
            <div className="instructionPageTitle">Quiz Exam Instructions</div>
            <div className="row">
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i className="fas fa-clock"></i>Test Duration
                </div>
                <div className="instructionPageSubText">{quizTimer} mins</div>
              </div>
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i className="fas fa-file-alt"></i>No. of questions
                </div>
                <div className="instructionPageSubText">{quizQuestion}</div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 instructionPageRight">
            <div className="instructionPageRightTitle">Instructions:</div>
            <ol className="instructionPageRightList">
              {quizInstruction.map((value) => {
                return <li>{value}</li>;
              })}
            </ol>
            <button className="instructionPageBtn" onClick={() => props.onNextClick()}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizsInstruction;
