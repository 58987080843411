// create a component that will display the results of the text based exam with our correct answers and the user's answers
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import configAxios from "../../axios.config";
import Img1 from "../../Assets/img/hybridexam/pic1.png";
import Img2 from "../../Assets/img/hybridexam/pic2.png";

const SubCard = (props) => {
  return (
    <>
      <div
        className="col-3 mb-0 subHeaderCards"
        style={{
          borderRight: "3px solid #f6f7fb",
        }}
      >
        <div
          className="subHeaderCard"
          style={{
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={props.imgsrc}
            className="img-fluid icon"
            alt="icon"
            style={{
              maxHeight: "80px",
            }}
          />
          <div
            className="title"
            style={{
              fontSize: "22px",
              letterSpacing: "0",
              margin: "15px 0 0 0",
            }}
          >
            {props.title}
          </div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default function TextBasesResults() {
  const { examId } = useParams();
  const [results, setResults] = useState();
  const [paragraphOutOfScore, setParagraphOutOfScore] = useState(0);
  const [finalParaScore, setFinalParaScore] = useState(0);

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get(`txbq/get-text-based-result/${examId}`)
      .then(({ data }) => {
        setResults(data.answer);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const paraPercentage = (finalParaScore / paragraphOutOfScore) * 100;

  let ParaResult = "Suppp";
  if (paraPercentage >= 75) {
    ParaResult = "Distinction";
  } else if (paraPercentage >= 50) {
    ParaResult = "Good";
  } else if (paraPercentage >= 30) {
    ParaResult = "Can do better";
  } else {
    ParaResult = "Should improve";
  }

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        padding: "20px",
      }}
    >
      <div
        className="subHeader"
        style={{
          backgroundColor: "#fff",
          padding: "40px 0",
          margin: "4px 0 0 0",
        }}
      >
        <div className="row gx-3">
          <div
            className="col-6 subHeadetTitle"
            style={{
              fontSize: "16px",
              letterSpacing: 0,
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Module 1 result
          </div>
          <SubCard imgsrc={Img1} title={`${finalParaScore}/${paragraphOutOfScore}`} text="Your Score" />
          <SubCard imgsrc={Img2} title={ParaResult} text="Result" />
        </div>
      </div>
      <h1 style={{ fontSize: "2em", marginTop: "20px", marginBottom: "20px" }}>Your Answers</h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "20px",
        }}
      >
        {results &&
          results.map((item, index) => (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                padding: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                overflow: "hidden",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                {index + 1}.
              </div>
              <div style={{ fontSize: "1.2em" }}>
                <h2 style={{ marginBottom: "10px", fontSize: "1.5em" }}>{item.questionId.question}</h2>
                <div>
                  <p style={{ marginBottom: "5px" }}>
                    <strong>Your answer:</strong>
                  </p>
                  <p style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: item.answer ? item.answer : "Not answered" }}></p>
                  <p style={{ marginBottom: "5px" }}>
                    <strong>Correct answer:</strong>
                  </p>
                  <p style={{ marginBottom: "0", color: "green" }}>{item.questionId.answer}</p>
                  <div style={{ display: "flex", marginTop: "10px", gap: "5px" }}>
                    <p style={{ marginBottom: "5px" }}>
                      <strong>Score:</strong>
                    </p>
                    <p style={{ marginBottom: "0" }}>{item.score}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
