import { useEffect, useRef, useState, useCallback } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { Prompt } from "react-router-dom";
import { BASE_URL } from "../env";
import "../Assets/css/exam.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { changeSolvedIndices, changeLanguageValues } from "../app/hybridExam/actions/action";
import configAxios from "../axios.config";
import NewHybridCompilerCoding from "../Pages/NewHybridExam_v3/NewHybridCompilerCoding";
import { updateCodingData } from "../app/hybridExam/actions/action";
import { changeTimer } from "../app/hybridExam/actions/action";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Webcam from "react-webcam";
import { HideWebCamStyled } from "../components/styled/HideWebcam.styled";

const NotificationPopup = ({ title, message, type }) => {
  Store.addNotification({
    title,
    message,
    type,
    // background: "pink",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

const Exam = ({ expiryTimestamp, hidenavbar }) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modes, setModes] = useState(true);
  function toggleMode() {
    setModes((prev) => !prev);
  }

  const [showQuestions, setshowQuestions] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const [prompt, setprompt] = useState(false);
  const [allData, setallData] = useState({
    _id: "",
    typeOfExam: "",
    type: "",
    topic: "",
    timer: "",
    questions: [],
    outOfScore: 0,
    name: "",
    language: "",
    courseid: "",
    category: "",
    attempts: 0,
    Subtopic: "",
  });
  const [timing, setTiming] = useState(1);
  const [code, setcode] = useState("");
  const [scoringArray, setscoringArray] = useState([]);
  const [questionsolved, setQuestionsolved] = useState([]);
  const [_id, setchoosenQuestion] = useState("");
  const [numberOfTabs, setnumberOfTabs] = useState(0);
  const [displayResult, setDisplayResult] = useState(true);
  const [displayWebCam, setDisplayWebCam] = useState(false);
  const [isCameraOn, setIsCamerOn] = useState(false);
  const location = useLocation();
  const webcamRef = useRef(null);
  // const [userData, setUserData] = useState(null);
  const webcamCallback = useCallback((node) => {
    if (!node || !node.video) return;
    // node.video.style.display = "none";
    webcamRef.current = node;
  }, []);
  // const capture = useCallback(() => {
  //   const imageSrc = webcamRef.current.getScreenshot();
  //   console.log(imageSrc);
  // }, [webcamRef]);

  function setquestionsolved(id) {
    setQuestionsolved((prev) => [...prev, id]);
  }
  const Timer = useTimer({
    expiryTimestamp,
    onExpire: () => {
      FinishExam();
    },
  });

  useEffect(() => {
    dispatch(changeTimer(Timer));
  }, [Timer, dispatch]);

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  useEffect(() => {
    if (!displayWebCam) return;
    if (!isCameraOn) return;

    const interval = setInterval(() => {
      if (!webcamRef.current) return;
      let imgSrc = webcamRef.current.getScreenshot();

      configAxios(localStorage.getItem("token")).post("/upload-s3-images/" + id, {
        webcamImg: imgSrc,
      });
    }, 1000 * 2 * 60);

    return () => clearInterval(interval);
  }, [webcamRef, displayWebCam, isCameraOn, id]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setnumberOfTabs((count) => count + 1);
    };

    window.addEventListener("blur", handleVisibilityChange);

    return () => {
      window.removeEventListener("blur", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => {
        if (!res.ok) {
          throw res; // throw the response object to be caught in the catch block
        }
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("emailID", data?.user?.email);
      })
      .catch((error) => {
        if (error && error.status === 401) {
          // Assuming history and window are defined
          history.push("/login?redirect=" + window.location.pathname + window.location.search);
        } else {
          // Handle other error scenarios or show a generic error message
          // You can log, show a notification, etc.
        }
      });
  }, [history]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    setprompt(true);
  };

  const handleEndConcert = async (e) => {};

  async function FinishExam() {
    var travelTime_new = moment().unix();
    const newtimer = localStorage.getItem("timer" + id) - travelTime_new;
    localStorage.removeItem("timer" + id);

    await fetch(BASE_URL + "/createCompilerTestResults", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: localStorage.getItem("emailID"),
        examId: id,
        scoring: scoringArray,
        timeTaken: newtimer,
        noOftabs: numberOfTabs,
        webCamOn: isCameraOn,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (displayResult === false) {
          history.push("/profile");
          return;
        }
        history.push(`examsubmitted/${id}`);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    fetch(BASE_URL + "/checkNumberofattempts/" + id + "/" + localStorage.getItem("emailID"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.exam) {
          history.push("/examdone");
        } else {
          configAxios(localStorage.getItem("token"))
            .get("/getExam/" + id)
            .then(({ data }) => {
              if (data.message.locked === true) {
                alert("Exam is locked");
                history.push("/profile");
              }
              let currentDate = new Date().getTime();
              let startDate = new Date(data.message.examDate).getTime();
              let endDate = new Date(data.message.examEndTime).getTime();
              if (currentDate < startDate) {
                alert("Exam not started yet");
                history.push("/profile");
              }
              if (currentDate > endDate) {
                alert("Exam ended");
                history.push("/profile");
              }
              if (data.message.displayResult === false) {
                setDisplayResult(false);
              }
              setallData(data.message);
              if (data.message.proctoring.video === true) {
                setDisplayWebCam(true);
              }
              if (data.message?.isPassword === true) {
                let authenticated = location?.state?.authenticated ? location.state.authenticated : false;
                if (!authenticated) {
                  NotificationPopup({
                    title: "UnAuthorazied",
                    message: "Enter the right password",
                    type: "warning",
                  });
                }
              }
              var objectArray = {};
              data.message.questions.map((item) => {
                objectArray[item._id] = [];
              });
              dispatch(changeSolvedIndices(objectArray));
              setTiming(parseInt(data.message.timer));
              if (data.message.questions && data.message.questions.length > 0) {
                dispatch(changeLanguageValues(data.message.questions[0].languages));
              }
              setshowPopup(true);
            });
        }
      });
  }, [id, history, dispatch, location]);

  function dispatchCodingData() {
    let totalScore = 0;
    scoringArray.forEach((val) => {
      totalScore = totalScore + val.score;
    });
    dispatch(
      updateCodingData({
        examId: allData._id,
        scoring: scoringArray,
        totalScore: totalScore,
        noOftabs: 0,
        timeTaken: "",
      })
    );
  }

  function startTimer() {
    const time = new Date();
    const travelTime = moment().add(timing, "minutes").unix();
    var travelTime_new = moment().unix();
    const newtimer = travelTime - travelTime_new;
    time.setSeconds(time.getSeconds() + newtimer);
    Timer.restart(time);
  }

  function CheckSolvedorNot(id) {
    // if()
    for (var singleid of questionsolved) {
      if (singleid === id) {
        return true;
      }
    }
    return false;
  }

  function CheckExistorNot(id) {
    if (id === _id) {
      return true;
    } else {
      return false;
    }
  }

  function ChooseQuestion(id, index) {
    setchoosenQuestion(id);
  }

  function getLanguage(lang) {
    switch (lang) {
      case "java":
        return "Java";
      case "python3":
        return "Python3";

      default:
        return lang.toUpperCase();
    }
  }

  useEffect(() => {
    if (allData.questions.length > 0) {
      setcode(() => {
        let temp = {};
        for (let i = 0; i < allData.questions.length; i++) {
          let question = allData.questions[i];
          for (let j = 0; j < question.questionCode.length; j++) {
            let question_code_spec = question.questionCode[j];
            if (!temp[question._id]) {
              temp = {
                ...temp,
                [question._id]: {
                  [getLanguage(question_code_spec.language)]: question_code_spec.code,
                },
              };
            } else {
              temp[question._id][getLanguage(question_code_spec.language)] = question_code_spec.code;
            }
          }
        }
        return temp;
      });
    }
  }, [allData]);

  return (
    <div>
      <Prompt
        when={prompt}
        message={(action) => {
          alert("Are you sure you want to finish the exam?");
        }}
      />
      {showPopup && (
        <div
          style={{
            position: "absolute",
            zIndex: "20",
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "500px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          Are you ready for the exam? &emsp;
          <button
            onClick={() => {
              // setshowExamCompiler(true);
              setshowPopup(false);
              setchoosenQuestion(allData?.questions[0]?._id);
              startTimer();
            }}
            className="btn btn-md btn-primary"
          >
            Yes, Let's Start
          </button>
        </div>
      )}
      <div
        className="d-flex flex-row flex-grow gx-1  "
        style={{
          filter: showPopup === true && "blur(2px)",
        }}
      >
        <AnimatePresence>
          <motion.div
            className={showQuestions ? " trans-width  " : " trans-width w-10 "}
            style={{
              backgroundColor: modes ? "#02203c" : "#fff",
              paddingRight: "1px",
              marginBottom: "1px",
            }}
          >
            <motion.div className="" style={{ backgroundColor: "#001528", position: "relative" }}>
              <motion.button
                style={{
                  float: "right",
                  top: "50vh",
                  border: "none",
                  background: "none",
                  fontSize: "25px",
                  marginLeft: "10px",
                  position: "relative",
                  left: "13px",
                  zIndex: 52,
                }}
                onClick={() => {
                  setshowQuestions((prev) => !prev);
                }}
              >
                <motion.i className={showQuestions ? " animating-toggle-exam shadow fas fa-chevron-right text-white " : "  animating-toggle-exam-closed shadow fas fa-chevron-right text-white  "}></motion.i>
              </motion.button>
            </motion.div>

            {displayWebCam && (
              <>
                <HideWebCamStyled>
                  <Webcam
                    audio={false}
                    height={200}
                    screenshotFormat="image/jpeg"
                    ref={webcamCallback}
                    width={300}
                    videoConstraints={{
                      facingMode: "user",
                    }}
                    onUserMediaError={() => {
                      setIsCamerOn(false);
                      alert("Camera turned off. Your attempt won't be considered. Turn on the camera and refresh the page");
                    }}
                    onUserMedia={() => {
                      setIsCamerOn(true);
                    }}
                  />
                </HideWebCamStyled>
              </>
            )}

            <div
              className="mt-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {allData &&
                allData?.questions?.map((val, index) => {
                  return (
                    <motion.button
                      key={val._id}
                      onClick={() => {
                        ChooseQuestion(val._id, index);
                      }}
                      className={!CheckExistorNot(val._id) ? " py-2 exam-question " : " py-2 exam-question selected-exam-question "}
                      style={{
                        backgroundColor: CheckSolvedorNot(val._id) ? "#1c1" : CheckExistorNot(val._id) ? (modes ? "fff" : "#cbd7f5") : undefined,
                        cursor: "pointer",
                        color: modes ? "#fff" : "rgb(117 117 117)",
                        fontSize: "18px",
                      }}
                    >
                      {showQuestions ? (
                        <motion.span>
                          {val.name}
                          {CheckSolvedorNot(val._id) && (
                            <div className="" style={{ float: "right" }}>
                              {" "}
                              <CheckCircleIcon />
                            </div>
                          )}
                        </motion.span>
                      ) : (
                        <motion.span>
                          {index + 1}{" "}
                          {CheckSolvedorNot(val._id) && (
                            <motion.div className="" style={{ float: "right" }}>
                              {" "}
                              <CheckCircleIcon />
                            </motion.div>
                          )}
                        </motion.span>
                      )}
                    </motion.button>
                  );
                })}
            </div>
          </motion.div>
        </AnimatePresence>
        <div className={showQuestions ? "" : ""} style={{ marginBottom: "1px", paddingLeft: "0px", flexGrow: 1 }}>
          {code && <NewHybridCompilerCoding questionsolved={questionsolved} setquestionsolved={setquestionsolved} FinishExam={FinishExam} setScoringArray={setscoringArray} code={code} id={_id} questions={allData.questions} setcode={setcode} scoringArray={scoringArray} updateCodingData={dispatchCodingData} modes={modes} toggleMode={toggleMode} />}
        </div>
      </div>
    </div>
  );
};

export default Exam;
