import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "../TextBasedExams/styles/textbasedexams.module.css";
import { AuthContext } from "../../Context/AuthContext";
import "react-notifications-component/dist/theme.css";
import logo from "../../Assets/img/cantilever-logo.png";
import 'suneditor/dist/css/suneditor.min.css';
import SunEditorComp from 'suneditor-react';
import { useDispatch } from "react-redux";
import { updateParagraphData } from "../../app/hybridExam/actions/action";

interface ITextBasedExams {
    userDP: string;
    userName: string;
    questionsArray: {
        _id: string;
        question: string;
        marks: number;
    }[];
    onSubmit: () => unknown;
}


const TextBasedExams = ({ userDP, userName, questionsArray, onSubmit }: ITextBasedExams) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [divSize, setDivSize] = useState({ width: 0 });
    const [index, setIndex] = useState<number>(0);
    const [showpopup, setshowpopup] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [scoreArray, setScoreArray] = useState<Array<{
        questionId: string;
        answer: string;
    }>>([]);

    const history = useHistory();
    const questionEditorRef = useRef<HTMLDivElement>(null);

    const authContextData = useContext(AuthContext);
    const { token } = authContextData;

    useEffect(() => {
        localStorage.setItem("currQues_para_hybrid", index.toString());
    }, [index]);

    useEffect(() => {
        setScoreArray(prev => {
            if (!prev.length) {
                return questionsArray.map(val => ({ questionId: val._id, answer: "" }));
            }

            return prev;
        })
    }, [questionsArray])


    useEffect(() => {
        const rectWidth = questionEditorRef.current?.getBoundingClientRect();

        rectWidth && setDivSize({ width: rectWidth.width });
    }, [])


    const changeHandler = (val: string) => {
        if (val === "<p><br></p>") {
            return;
        }
        setScoreArray((prev) => {
            if (!prev) return prev;

            let prev_cpy: Array<{
                questionId: string;
                answer: string;
            }> = JSON.parse(JSON.stringify(prev));
            const currQuesCount = parseInt(localStorage.getItem("currQues_para_hybrid") || "1");
            prev_cpy[currQuesCount].answer = val;

            dispatch(updateParagraphData({
                examId: "",
                questions_Attempted: prev_cpy,
            }))
            return prev_cpy;
        });
    };

    const length = questionsArray ? questionsArray.length : 0;
    let maxLenOfEachQuestion = Math.floor((divSize.width / 100) * 35);
    return (
        <div id="main">
            {showpopup === true && (
                <div
                    className={styles.popup_submission}
                    style={{ backdropFilter: "blur(10px)" }}
                >
                    <div className="d-flex flex-column">
                        <span className={styles.popup_text}>
                            Are you sure that you want to submit your Exam?
                        </span>
                        <div className="d-flex flex-row justify-content-start">
                            <button
                                onClick={() => {
                                    setshowpopup(false);
                                }}
                                className={styles.submission_quiz_button}
                                style={{
                                    background: "transparent",
                                    border: "1px solid white",
                                    color: "#fff",
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={onSubmit}
                                className={styles.submission_quiz_button}
                            >
                                {spinner ? (
                                    <div className="spinner-border spinner-border-sm"></div>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
                <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
                    {token != null ? (
                        <>
                            <div className={styles.quizPageNavbar}>
                                <img src={logo} className={styles.quizPageLogo} alt="logo" />
                                <div className={styles.quizPageNavbarRight}>
                                    <div className={styles.quizPageProfileDetail}>
                                        <img src={userDP} className={styles.quizPageProfilePic} alt="profile" />
                                        <span> {userName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.quizQuestionPage}>

                                <div className="mb-0">
                                    <div className={styles.quizQuestionPageLeft}>
                                        <div className="row" style={{ flexGrow: 1, overflowY: "auto" }}>
                                            <div className="col-12 mb-0">
                                                <div className={styles.quizQuestionPageQuestionNum}>
                                                    Question No. {index + 1}
                                                </div>
                                                <hr />
                                                <div ref={questionEditorRef} className={questionsArray[index] && questionsArray[index].question.length > maxLenOfEachQuestion ? styles.questionEditorWrapper : `${styles.questionEditorWrapper} ${styles.leftRight}`} >

                                                    <div className={questionsArray[index] && questionsArray[index].question.length > maxLenOfEachQuestion ? styles.left : `${styles.left} ${styles.fullLengthLeft}`}>
                                                        <div className={styles.quizQuestionPageQuestion}>
                                                            {questionsArray[index] && questionsArray[index].question}
                                                        </div>
                                                    </div>
                                                    <div className={questionsArray[index] && questionsArray[index].question.length > maxLenOfEachQuestion ? `${styles.right} ${styles.getFullLen}` : `${styles.right} ${styles.fullLengthRight}`}>  {/*change div wrapper */}

                                                        {scoreArray.length > 0 && questionsArray[index] && <SunEditorComp
                                                            lang="en"
                                                            width="100%"
                                                            height="inherit"

                                                            autoFocus={true}
                                                            onResizeEditor={(height, prevHeight) => {
                                                                console.log({
                                                                    height,
                                                                    prevHeight
                                                                })
                                                            }}
                                                            onChange={changeHandler}
                                                            setContents={scoreArray[index].answer}
                                                            hideToolbar={false}
                                                            onPaste={(e, cleanData, maxCharCount) => {
                                                                console.log(e, cleanData, maxCharCount);
                                                            }}
                                                            setOptions={{
                                                                height: "100%",
                                                                minHeight: "250px",
                                                                resizingBar: false,

                                                                buttonList: [
                                                                    [
                                                                        "undo",
                                                                        "redo",
                                                                        "fontSize",
                                                                        "bold",
                                                                        "underline",
                                                                        "italic",
                                                                        "subscript",
                                                                        "superscript",
                                                                        "hiliteColor",
                                                                        "outdent",
                                                                        "indent",
                                                                        "horizontalRule",
                                                                        "list",
                                                                    ],
                                                                ],
                                                            }}
                                                        />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 d-sm-flex justify-content-between" style={{
                                                marginBottom: 0
                                            }}>
                                                <div className="rightside">
                                                    {index === 0 ? null : (
                                                        <button
                                                            onClick={(e) => {
                                                                if (index === 0) {
                                                                } else {
                                                                    setIndex((prev) => prev - 1);
                                                                }
                                                            }}
                                                            className={styles.quizPagePrevBtn}
                                                        >
                                                            <i className="fas fa-arrow-left"></i> Prev
                                                        </button>
                                                    )}
                                                    {index === length - 1 ? null : (
                                                        <button
                                                            onClick={(e) => {
                                                                if (index === length - 1) {
                                                                } else {
                                                                    setIndex(prev => prev + 1);
                                                                }
                                                            }}
                                                            className={styles.quizPageNextBtn}
                                                        >
                                                            Next <i className="fas fa-arrow-right"></i>
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="leftside">
                                                    <button
                                                        className={styles.quizPageExamSubmitBtn}
                                                        onClick={() => {
                                                            setshowpopup(true);
                                                        }}
                                                    >
                                                        Submit Section
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (
                        history.push("/")
                    )}
                </div>
            </div>
        </div>
    );
};

export default TextBasedExams;