import {
  useEffect,
  useMemo,
  useState
} from "react";
import { useParams } from "react-router-dom";
import { Prompt } from "react-router-dom";
import "../../Assets/css/exam.css";
import { AnimatePresence, motion } from "framer-motion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import NewHybridCompilerCoding from "./NewHybridCompilerCoding";
import { useDispatch, useSelector } from "react-redux";
import { changeSolvedIndices, updateCodingData } from "../../app/hybridExam/actions/action";
import { configAxiosLambda } from "../../axios.config";
import { getExamProgress } from "./UtilsAPI";

interface ICodingExam {
  codingData: ICodeExamData | undefined;
  onFinish: () => Promise<unknown>;
}
function getLanguage(lang: string) {
  switch (lang) {
    case "java":
      return "Java";
    case "python3":
      return "Python3";

    default:
      return lang.toUpperCase();
  }
}

const CodingExam = ({ onFinish, codingData }: ICodingExam) => {
  const { id } = useParams();
  // const [timing, setTiming] = useState(1);
  const [modes, setModes] = useState(true);
  const [allData, setallData] = useState<ICodeExamData>({
    _id: "",
    typeOfExam: "",
    type: "",
    topic: "",
    timer: "",
    questions: [],
    outOfScore: 0,
    name: "",
    language: "",
    courseid: "",
    category: "",
    attempts: 0,
    Subtopic: ""
  });
  // const [showExamCompiler, setshowExamCompiler] = useState(false);
  const [showQuestions, setshowQuestions] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const [code, setcode] = useState<codeData>();
  const [scoringArray, setScoringArray] = useState<Array<IScoringArray>>([]);
  const [questionsolved, setQuestionSolved] = useState<Array<string>>([]);
  const [_id, setchoosenQuestion] = useState("");
  const [prompt, setprompt] = useState(false);

  const {
    hours,
    minutes,
    seconds
  } = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.examTimer)

  const codeArr = useMemo(() => {
    if (!code) return [];

    let code_arr: Array<{
      questionId: string,
      language: string,
      code: string
    }> = [];

    allData.questions.forEach(val => {
      val.languages.forEach(lang => {
        code_arr.push({
          questionId: val._id,
          language: lang,
          code: code[val._id][lang]
        })
      })
    })

    return code_arr;

  }, [code, allData])

  // const location = useLocation();
  const dispatch = useDispatch();

  function setquestionsolved(id: string) {
    if (!questionsolved.includes(id)) {
      setQuestionSolved((prev) => [...prev, id])
    }
  }

  useEffect(() => {

    getExamProgress(id).then(data => {
      let codingProgress = data.examProgress.codingProgress;
      let codingResult = data.examProgress.codingResult;
      if (codingResult && codingResult.length > 0) {
        let cod_result = data.examProgress.codingResult.map(val => {
          let temp = { ...val };
          if (temp["_id"]) delete temp["_id"];

          return temp;
        })
        setScoringArray(cod_result);
      }
      if (codingProgress && codingProgress.length > 0) {
        codingProgress.forEach(val => {
          setcode(prev => {
            if (prev) {
              prev[val.questionId][val.language] = val.code;
              let temp_obj = JSON.parse(JSON.stringify(prev));

              return temp_obj;
            }
            return prev;
          })
        });
      }
    }).catch(err => console.log(err))
  }, [id])

  /**
   * Sets the boiler plate code
   */
  useEffect(() => {
    if (allData.questions.length > 0) {
      setcode(() => {
        let temp = {};
        for (let i = 0; i < allData.questions.length; i++) {
          let question = allData.questions[i];
          for (let j = 0; j < question.questionCode.length; j++) {
            let question_code_spec = question.questionCode[j];
            if (!temp[question._id]) {
              temp = {
                ...temp,
                [question._id]: {
                  [getLanguage(question_code_spec.language)]: question_code_spec.code
                }
              };
            } else {
              temp[question._id][getLanguage(question_code_spec.language)] = question_code_spec.code;
            }
          }
        }
        return temp;
      })
    }
  }, [allData])

  useEffect(() => {

    const convertToMinutes = (hours: number, minutes: number, seconds): number => {
      let total_minutes = hours * 60 + minutes + seconds / 60;
      return parseFloat(total_minutes.toFixed(2));
    }

    let intervalAPI = setInterval(() => {
      let time_rem = convertToMinutes(hours, minutes, seconds);
      configAxiosLambda(localStorage.getItem("token") || "").post(`/progress/update-coding-progress`, {
        examId: id,
        codingProgress: codeArr,
        timeRemaining: time_rem,
        codingResult: scoringArray
      }).catch(err => {
        console.log(err);
      })
    }, 5000);

    return () => clearInterval(intervalAPI);
  }, [codeArr, id])

  function toggleMode() {
    setModes(prev => !prev);
  }

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  //   setprompt(true);
  // };

  useEffect(() => {
    if (!codingData) return;

    /**
     * * Hard coded courseIds for Chitkara and chitkara testing
     */
    setallData(codingData);
    var objectArray: any = {};
    codingData.questions.map((item) => {
      objectArray[item._id] = [];
    });
    dispatch(changeSolvedIndices(objectArray));
    // setTiming(parseInt(codingData.timer))
    if (codingData.questions.length !== 0) {
      setchoosenQuestion(codingData.questions[0]._id)
    }
    const travelTime = moment()
      .add(codingData.timer, "minutes")
      .unix();
    if (localStorage.getItem("timer" + id) === null) {
      localStorage.setItem("timer" + id, `${travelTime}`);
    }
  }, [codingData, id]);

  function CheckSolvedorNot(id) {
    // if()
    for (var singleid of questionsolved) {
      if (singleid === id) {
        return true;
      }
    }
    return false;
  }

  function CheckExistorNot(id) {
    if (id === _id) {
      return true;
    } else {
      return false;
    }
  }

  function dispatchCodingData() {
    let totalScore = 0;
    scoringArray.forEach(val => {
      totalScore = totalScore + val.score;
    });
    dispatch(updateCodingData({
      examId: allData._id,
      scoring: scoringArray,
      totalScore: totalScore,
      noOftabs: 0,
      timeTaken: ""
    }))
  }

  function ChooseQuestion(id) {
    setchoosenQuestion(id);
  }

  return (
    <div className="maincontainer">
      <Prompt
        when={prompt}
        message="Are you sure you want to finish the exam?"
      />
      {showPopup && (
        <div
          style={{
            position: "absolute",
            zIndex: 20,
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "500px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          Are you ready for the exam? &emsp;
          <button
            onClick={() => {
              setshowPopup(false);
            }}
            className="btn btn-md btn-primary"
          >
            Yes, Let's Start
          </button>
        </div>
      )}

      <div
        className="d-flex flex-row flex-grow gx-1  "
        style={{ filter: showPopup === true ? "blur(2px)" : undefined }}
      >
        {/* @ts-ignore */}
        <AnimatePresence>
          <motion.div
            className={
              showQuestions ? " trans-width  " : " trans-width w-10 "
            }
            style={{
              backgroundColor: modes ? "#02203c" : "#fff",
              paddingRight: "1px",
              marginBottom: "1px",
            }}
          >
            <motion.div
              className=""
              style={{ backgroundColor: "#001528", position: "relative" }}
            >
              <motion.button
                style={{
                  float: "right",
                  top: "50vh",
                  border: "none",
                  background: "none",
                  fontSize: "25px",
                  marginLeft: "10px",
                  position: "relative",
                  // top: "50%",
                  left: "13px",
                  zIndex: 52,
                }}
                onClick={() => {
                  setshowQuestions(prev => !prev);
                }}
              >
                <motion.i
                  className={
                    showQuestions
                      ? " animating-toggle-exam shadow fas fa-chevron-right text-white "
                      : "  animating-toggle-exam-closed shadow fas fa-chevron-right text-white  "
                  }
                ></motion.i>
              </motion.button>
            </motion.div>
            <motion.div className="mt-4" style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start"
            }}>
              {allData && allData.questions.map((val, index) => {
                return (
                  <motion.button
                    key={val._id}
                    onClick={() => {
                      ChooseQuestion(val._id);
                    }}
                    className={
                      !CheckExistorNot(val._id)
                        ? " py-2 exam-question "
                        : " py-2 exam-question selected-exam-question "
                    }
                    style={{
                      backgroundColor: CheckSolvedorNot(val._id)
                        ? "#1c1"
                        : CheckExistorNot(val._id) ? (modes ? "fff" : "#cbd7f5") : undefined,
                      cursor: "pointer",
                      color: modes ? "#fff" : "rgb(117,117, 117)",
                      fontSize: "18px",
                    }}
                  >
                    {showQuestions ? (
                      <motion.span>
                        {val.name}
                        {CheckSolvedorNot(val._id) && (
                          <div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </div>
                        )}
                      </motion.span>
                    ) : (
                      <motion.span>
                        {index + 1}{" "}
                        {CheckSolvedorNot(val._id) && (
                          <motion.div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </motion.div>
                        )}
                      </motion.span>
                    )}
                  </motion.button>
                );
              })}
            </motion.div>
          </motion.div>
        </AnimatePresence>
        <div
          className={showQuestions ? "" : ""}
          style={{ marginBottom: "1px", paddingLeft: "0px", flexGrow: 1 }}
        >
          {code && <NewHybridCompilerCoding
            questionsolved={questionsolved}
            setquestionsolved={setquestionsolved}
            FinishExam={onFinish}
            setScoringArray={setScoringArray}
            code={code}
            id={_id}
            questions={allData.questions}
            setcode={setcode}
            scoringArray={scoringArray}
            updateCodingData={dispatchCodingData}
            modes={modes}
            toggleMode={toggleMode}
          />}
        </div>
      </div>
    </div>
  );
};

export default CodingExam;
