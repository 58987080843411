import React, { useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../Assets/css/codinginstruction.css";
// import { BASE_URL } from "../env";
import logo from "../Assets/img/cantilever-logo.png";
import configAxios from "../axios.config";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const QuizInstructions = ({ hidenavbar }) => {
  const queryParams = useRef(new URLSearchParams(window.location.search));
  const history = useHistory();
  const [PopUp, setPopUp] = useState(false);
  const [Errmsg, setErrmsg] = useState("");
  const [isPassword, setIsPassword] = useState();
  const [Show, setShow] = useState(false);
  const password = useRef(null);

  useEffect(() => {
    if (!hidenavbar) return;
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  function showPopup() {
    setShow(true);
  }

  useEffect(() => {
    const id = queryParams.current.get("id");

    configAxios(localStorage.getItem("token"))
      .get(`/checkNumberOfAttemptsLeft/${id}`)
      .then(({ data }) => console.log(data))
      .catch(({ response }) => {
        switch (response.status) {
          case 401:
            if (id === "661ce9fb1b6fd17088a86c44" || id === "6618f149118ecf823820d6fb") {
              history.push("/chitkara-signup?redirect=" + window.location.pathname + window.location.search);
            } else {
              history.push("/login?redirect=" + window.location.pathname + window.location.search);
            }
            break;

          case 422:
            setPopUp(true);
            setErrmsg(response.data.message);
            break;

          case 500:
            if (id === "661ce9fb1b6fd17088a86c44" || id === "6618f149118ecf823820d6fb") {
              history.push("/chitkara-signup?redirect=" + window.location.pathname + window.location.search);
            } else {
              history.push("/login?redirect=" + window.location.pathname + window.location.search);
            }
            break;

          default:
        }
      });
  }, [history]);

  useEffect(() => {
    const id = queryParams.current.get("id");
    configAxios(localStorage.getItem("token"))
      .get("/getQuizEXamById/" + id)
      .then(({ data }) => {
        const examData = data.data;
        if (examData.isPassword === true || examData.isPassword === false) {
          setIsPassword(examData.isPassword);
        } else {
          setIsPassword(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function authenticateUser() {
    configAxios(localStorage.getItem("token"))
      .post("/authenticate-exam-key/" + queryParams.current.get("id"), {
        examKey: password.current.value,
      })
      .then(({ data }) => {
        if (data.authenticated === true) {
          history.push("/quizPortal/" + queryParams.current.get("id"), {
            authenticated: true,
          });
        } else {
          // history.push("/profile");
          alert("Wrong Password");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Wrong Password");
        // history.push("/profile");
      });
  }
  return (
    <>
      <div>
        <Modal show={Show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Exam Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Enter Password</Form.Label>
            <Form.Control name="oldPassword" type="text" placeholder="Exam Password" ref={password} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              // onSubmit={onHandleSubmit}
              variant="primary"
              onClick={authenticateUser}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="d-grid place-content-center">
        {PopUp && (
          <div
            style={{
              position: "absolute",
              zIndex: "20",
              backdropFilter: "brightness(0)",
              top: "100px",
              left: "80px",
              right: "80px",
              bottom: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              padding: "20px",
            }}
          >
            {Errmsg}
            &emsp;
            <button
              onClick={() => {
                history.push("/profile");
              }}
              className="btn btn-md btn-success"
            >
              Continue
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 instructionPageLeft">
            <img src={logo} className="instructionPageLogo" alt="logo" />
            <div className="instructionPageTitle">
              Welcome to Cantilever Labs. <br />
              Competitive Quiz Exam
            </div>
            <div className="row">
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i class="fas fa-clock"></i>Test Duration
                </div>
                <div className="instructionPageSubText">{queryParams.current.get("duration")} mins</div>
              </div>
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i class="fas fa-file-alt"></i>No. of questions
                </div>
                <div className="instructionPageSubText">{queryParams.current.get("questions")}</div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 instructionPageRight">
            <div className="instructionPageRightTitle">Instructions:</div>
            <ol className="instructionPageRightList">
              <li>This is a timed test. Please make sure you are not interrupted during the test, as the timer cant be paused once started.</li>
              <li>Please ensure you have a stable internet connection.</li>
              <li>Please make sure that you do not reload the page.</li>
              {/* <li>{`log3((587)/5) is read as log of 587 divided by 5 to the base 3`}</li> */}
            </ol>
            {isPassword === true && (
              <div onClick={showPopup} className="instructionPageBtn">
                Enter Password
              </div>
            )}
            {isPassword === false && (
              <NavLink to={"/quizPortal/" + queryParams.current.get("id")} className="instructionPageBtn">
                Continue
              </NavLink>
            )}
            {isPassword === undefined && (
              <div className="instructionPageBtn">
                <div className="spinner-border spinner-border-sm"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizInstructions;
