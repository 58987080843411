import styled from "styled-components";

const ExamSubmissionStyled = styled.div`
    min-height: 100vh;
    background: linear-gradient(to bottom, #e6f2ff, #ffffff);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .card {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 28rem;
    }

    .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    }

    .check-icon {
    color: #22c55e;
    margin-bottom: 1rem;
    animation: bounce 1s infinite;
    }

    @keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    }

    h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 0.5rem;
    }

    .success-message {
    color: #4b5563;
    margin-bottom: 1.5rem;
    }

    .next-steps {
    background-color: #eff6ff;
    padding: 1rem;
    border-radius: 0.5rem;
    }

    .next-steps h2 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1e40af;
    margin-bottom: 0.5rem;
    }

    .next-steps p {
    color: #2563eb;
    }

    @media (max-width: 640px) {
        .card {
            margin: 0 1rem;
        }
    }
`

export default ExamSubmissionStyled;