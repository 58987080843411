import { CheckCircle } from '@mui/icons-material';
import ExamSubmissionStyled from "./styled/ExamSubmissionStyled"
import { useEffect } from 'react';

export default function ExamSubmission({ hidenavbar }) {

    useEffect(() => {
        hidenavbar(true);
        return () => {
            hidenavbar(false);
        };
    }, [hidenavbar]);

    return (
        <ExamSubmissionStyled>
            <div className="card">
                <div className="card-content">
                    <div className="check-icon">
                        <CheckCircle fontSize='large' />
                    </div>
                    <h1>Exam Submitted Successfully!</h1>
                    <p className="success-message">
                        Your exam has been successfully submitted. Great job on completing it!
                    </p>
                    <div className="next-steps">
                        <h2>What's Next?</h2>
                        <p>
                            Your exam result will be sent to your registered email address. Please check your inbox (and spam folder) in the coming days.
                        </p>
                    </div>
                </div>
            </div>
        </ExamSubmissionStyled>
    )
}