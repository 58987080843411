import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../Assets/css/codinginstruction.css";
import logo from "../../Assets/img/cantilever-logo.png";
import { useDispatch } from "react-redux";
import configAxios from "../../axios.config";
import { TParticularExamDataHybridResponse } from "./types";
import { useQuery } from "react-query";
import { QUERY_KEY } from "../../constants/querykey";

function MainInstruction(props: { onNextClick: () => unknown }) {
    const [examTimer, setExamTimer] = useState<string>("");
    const [mainInstruction, setMainInstruction] = useState<Array<string>>([]);
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { data: hybridExamData } = useQuery([QUERY_KEY.getParticularExamHybrid, id], () => {
        return configAxios(localStorage.getItem("token")).get<TParticularExamDataHybridResponse>(`/getparticularexam/${id}`).then(({ data }) => data)
    }, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (!hybridExamData) return;

        setMainInstruction(hybridExamData.exam.instructionmain);
        setExamTimer(hybridExamData.exam.exam_timer);
    }, [hybridExamData])

    return (
        <>
            <div className="d-grid place-content-center"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-5 instructionPageLeft">
                        <img src={logo} className="instructionPageLogo" alt="logo" />
                        <div className="instructionPageTitle">
                            Welcome to Cantilever Labs. <br />
                            Quiz & Coding Exam
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="instructionPageSubTitle">
                                    <i className="fas fa-clock"></i>Total Test Duration
                                </div>
                                <div className="instructionPageSubText">{examTimer} mins</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 instructionPageRight">
                        <div className="instructionPageRightTitle">Instructions:</div>
                        <ol className="instructionPageRightList">
                            {mainInstruction?.map((value) => {
                                return (
                                    <>
                                        <li> {value}</li>
                                    </>
                                );
                            })}
                        </ol>
                        <button
                            onClick={() => {
                                props.onNextClick()
                            }}
                            className="instructionPageBtn"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainInstruction