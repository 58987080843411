import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import styles from "../Assets/css/quizz.module.css";
import configAxios, { configAxiosLambda } from "../axios.config";
import { useTimer } from "react-timer-hook";
import { AuthContext } from "../Context/AuthContext";
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";
import "../Assets/css/quizznew.css";
import logo from "../Assets/img/cantilever-logo.png";
import moment from "moment";
import {
  updateQuizProgress,
  getExamProgress,
  deleteProgress,
} from "./NewHybridExam_v3/UtilsAPI";
import useProfile from "../hooks/useProfile";
import Webcam from "react-webcam";
import { HideWebCamStyled } from "../components/styled/HideWebcam.styled";

interface IQuizQuestion {
  _id: string;
  answers: Array<string>;
  correctAnswer: Array<string>;
  question: string;
  questionType: string;
  questionPic?: string;
  timer?: string;
  answerSelectionType: string;
  messageForCorrectAnswer?: string;
  messageForIncorrectAnswer?: string;
  explanation?: string;
  point?: string;
  topic?: string;
  marks: number;
}

interface IQuizData {
  _id: string;
  examAttempted: number;
  category: string;
  company: string;
  timer: string;
  description: string;
  courseId: string;
  examName: string;
  attempts: string;
  questions: Array<IQuizQuestion>;
  textBasedQuestions: Array<string>;
  typeOfExam: string;
  genre: string;
  language: string;
  type: string;
  topic: string;
  Subtopic: string;
  locked?: boolean;
  outOfScore: string;
  questionWeight?: number;
  displayResult?: boolean;
  isPassword?: boolean;
  examDate?: string;
  examEndTime?: string;
  proctoring: {
    video: boolean;
    fullScreen: boolean;
  };
}

const NotificationPopup = ({ title, message, type }) => {
  Store.addNotification({
    title,
    message,
    type,
    // background: "pink",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

const QuizzQuestions = ({ expiryTimestamp, hidenavbar }) => {
  const questionWeight = useRef(1);
  const displayResult = useRef<boolean>(true);
  const typeOfExam = useRef("");
  const { examId } = useParams<{ examId: string }>();
  const [questionsArray, setQuestionsArray] = useState<Array<IQuizQuestion>>();
  const [index, setIndex] = useState<number>(0);
  const [userDP, setuserDP] = useState("");
  const [questions_Attempted, setQuestionsAttempted] = useState<Array<string>>(
    []
  );
  const [unquestions_Attempted, setUnQuestionsAttempted] = useState<
    Array<IQuizQuestion>
  >([]);
  const [questions_review, setQuestionsReview] = useState<Array<string>>([]);
  const authContextData = useContext(AuthContext);
  const { token } = authContextData;
  const [showpopup, setshowpopup] = useState(false);
  const [show, setshow] = useState(false);
  const [numberofTabs, setnumberofTabs] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [currQues, setcurrQues] = useState(1);
  const [displayWebCam, setDisplayWebCam] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [isCameraOn, setIsCamerOn] = useState(false);
  const webcamRef: React.MutableRefObject<Webcam | null> = useRef<Webcam>(null);
  const [textExamExists, setTextExamExists] = useState(false);
  const [userData, setUserData] = useState<{
    userName: string;
    email: string;
    userId: string;
  }>();
  const [quizProgress, setQuizProgress] = useState<
    Array<{
      questionId: string;
      selectedAnswer: string;
    }>
  >([]);
  const [scoreArray, setScoreArray] = useState<
    Array<{
      questionNo: number;
      score: number;
      questionId: string;
      correct: boolean;
      option: string;
    }>
  >([]);
  const [examArray, setExamsArray] = useState<IQuizData>({
    _id: "",
    examAttempted: 0,
    category: "",
    company: "",
    timer: "",
    description: "",
    courseId: "",
    examName: "",
    attempts: "",
    questions: [],
    typeOfExam: "",
    genre: "",
    language: "",
    type: "",
    topic: "",
    Subtopic: "",
    textBasedQuestions: [],
    outOfScore: "",
    proctoring: {
      video: false,
      fullScreen: false,
    },
  });
  const [isWebSocketConnected, setIsWebSocketConnected] =
    useState<boolean>(false);
  const webcamCallback = useCallback<(node: Webcam) => unknown>((node) => {
    if (!node || !node.video) return;
    webcamRef.current = node;
  }, []);
  const history = useHistory();
  useProfile({
    onSucess: ({ data }) => {
      setuserDP(data?.user?.profile_pic);
      setUserData({
        userName:
          (data.user.firstName ? data.user.firstName : "") +
          " " +
          (data.user.lastName ? data.user.lastName : ""),
        userId: data.user._id,
        email: data.user.email,
      });
    },
    onError: (err: any) => {
      if (err.response.status === 401) {
        history.push("/login?redirect=/quizPortal/" + examId);
        NotificationPopup({
          title: "Unauthorized",
          message: "Please Login to attempt the exam",
          type: "danger",
        });
      }
    },
  });
  const location = useLocation();
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      submithandler();
    },
  });

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  useEffect(() => {
    let socketTimeout: ReturnType<typeof setTimeout> | null = null;
    if (!examId || !userData) return;
    if (isWebSocketConnected) {
      return;
    }

    const live_data_socket = new WebSocket(
      `wss://g7uvpr5cr9.execute-api.ap-south-1.amazonaws.com/production?examId=${examId}&name=${userData.userName}&email=${userData.email}&userId=${userData.userId}`
    );

    live_data_socket.onopen = function () { };

    live_data_socket.onclose = function () {
      setIsWebSocketConnected(true);

      socketTimeout = setTimeout(() => {
        setIsWebSocketConnected(false);
      }, 5000);
    };

    return () => {
      live_data_socket.close();

      if (socketTimeout) {
        clearTimeout(socketTimeout);
      }
    };
  }, [userData, examId, isWebSocketConnected]);

  useEffect(() => {
    function startTimer(travelTime: number) {
      const time = new Date();
      localStorage.setItem("timer" + examId, `${travelTime}`);
      var travelTime_new = moment().unix();
      const newtimer = travelTime - travelTime_new;
      const minutes_timer = newtimer / 60;
      time.setSeconds(time.getSeconds() + 60 * minutes_timer);
      restart(time);
    }

    getExamProgress(examId)
      .then(({ examProgress }: any) => {
        if (!examProgress) return;
        const review = examProgress.quizResult.review.map((item: any) => {
          delete item._id;
          return item;
        });
        let minutes = Math.floor(examProgress.timeRemaining);
        let seconds = (examProgress.timeRemaining % 1).toFixed(2);
        const travelTime = moment()
          .add(minutes, "minutes")
          .add(seconds, "seconds")
          .unix();
        startTimer(travelTime);

        setQuestionsReview(review);

        const tempScore: Array<any> = [];
        examProgress.quizResult.score.forEach((val) => {
          // update the question number
          if (questionsArray) {
            val.questionNo =
              questionsArray.findIndex((ques) => ques._id === val.questionId) +
              1;
            tempScore.push(val);
          }
        });
        setScoreArray(tempScore);
        if (examProgress.quizProgress) {
          let quiz_progress = examProgress.quizProgress.map((val) => {
            let temp_obj = { ...val };
            delete temp_obj._id;
            return temp_obj;
          });
          setQuizProgress(quiz_progress);
        }
        setQuestionsAttempted(examProgress.quizResult.questions_Attempted);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [examId, questionsArray]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setnumberofTabs((count) => count + 1);
    };

    window.addEventListener("blur", handleVisibilityChange);

    return () => {
      window.removeEventListener("blur", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  useEffect(() => {
    let finalScore = 0;
    let temp_score: Array<{
      questionId: string;
      score: number;
      correct?: boolean;
      questionNo: number;
      option: string;
    }> = [...scoreArray];

    temp_score.forEach((val) => {
      finalScore = finalScore + val.score;
      delete val["correct"];
    });
    let quizExamData = {
      score: temp_score,
      review: questions_review,
      examId: examId,
      questions_Attempted,
      finalScore,
    };
    if (quizProgress.length !== 0) {
      let time_rem = convertToMinutes(hours, minutes, seconds);
      updateQuizProgress(quizProgress, quizExamData, examId, time_rem);
    }
  }, [questions_Attempted, scoreArray, questions_review, examId, quizProgress]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    // setprompt(true);
  };

  useEffect(() => {
    if (!displayWebCam) return;
    if (!isCameraOn) return;

    const interval = setInterval(() => {
      if (!webcamRef.current) return;
      let imgSrc = webcamRef.current.getScreenshot();

      configAxios(localStorage.getItem("token")).post(
        "/upload-s3-images/" + examId,
        {
          webcamImg: imgSrc,
        }
      );
    }, 1000 * 2 * 60);

    return () => clearInterval(interval);
  }, [webcamRef, displayWebCam, isCameraOn, examId]);

  useEffect(() => {
    if (!examId) return;
    if (!examArray._id) return;

    let axiosInstance = configAxios(localStorage.getItem("token"));
    axiosInstance.get(`/checkNumberOfAttemptsLeft/${examId}`).catch((err) => {
      console.log(err.response);
      switch (err.response.status) {
        case 422:
          if (displayResult.current === true) history.push("/quizexamsubmitted/" + examId);
          else history.push("/profile");
          NotificationPopup({
            title: "Limit exceeded",
            message: "you have exhausted the number of attempts",
            type: "danger",
          });
          return;

        case 401:
          history.push("/login?redirect=/quizPortal/" + examId);
          NotificationPopup({
            title: "Login",
            message: "Login to attempt the exam",
            type: "danger",
          });
          break;
        default:
          return;
      }
    });
  }, [examId, history, examArray]);

  window.onload = function () {
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      sessionStorage.removeItem("reloading");
      // myFunction();
      submithandler();
    }
  };

  const handleEndConcert = async () => {
    // sessionStorage.setItem("reloading", "true");
    // console.log(e);
  };

  useEffect(() => {
    if (!examId || examId === "") return;
    if (!history) return;

    function startTimer(travelTime: number) {
      const time = new Date();
      localStorage.setItem("timer" + examId, `${travelTime}`);
      var travelTime_new = moment().unix();
      const newtimer = travelTime - travelTime_new;
      const minutes_timer = newtimer / 60;
      time.setSeconds(time.getSeconds() + 60 * minutes_timer);
      restart(time);
    }

    configAxios(localStorage.getItem("token"))
      .get<{ data: IQuizData }>("/getQuizEXamById/" + examId)
      .then(({ data }) => {
        if (data.data.locked === true) {
          alert("Exam is locked");
          history.push("/profile");
        }
        let start_date_str = data.data.examDate;
        let end_date_str = data.data.examEndTime;
        if (start_date_str && end_date_str) {
          let currentDate = new Date().getTime();
          let startDate = new Date(start_date_str).getTime();
          let endDate = new Date(end_date_str).getTime();
          if (currentDate < startDate) {
            alert("Exam not started yet");
            history.push("/profile");
          }
          if (currentDate > endDate) {
            alert("Exam ended");
            history.push("/profile");
          }
        }
        if (data.data.proctoring.video === true) {
          setDisplayWebCam(true);
        }
        if (data.data.displayResult === false) {
          displayResult.current = data.data.displayResult;
        }

        typeOfExam.current = data.data.typeOfExam;
        setExamsArray(data.data);
        setQuestionsArray(shuffleArray(data.data.questions));
        if (data.data?.textBasedQuestions?.length >= 1) {
          setTextExamExists(true);
        }
        setUnQuestionsAttempted(data.data.questions);
        var travelTime = moment()
          .add(parseInt(data.data.timer), "minutes")
          .unix();
        startTimer(travelTime);
        if (data.data.questionWeight) {
          questionWeight.current = data.data.questionWeight;
        }
        if (data.data?.isPassword === true) {
          let authenticated = location?.state?.authenticated
            ? location.state.authenticated
            : false;
          if (!authenticated) {
            NotificationPopup({
              title: "UnAuthorazied",
              message: "Enter the right password",
              type: "warning",
            });
          }
        }
      });
  }, [examId, history]);

  function shuffleArray(array: Array<IQuizQuestion>): Array<IQuizQuestion> {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  function convertToMinutes(hours: number, minutes: number, seconds): number {
    let total_minutes = hours * 60 + minutes + seconds / 60;
    return parseFloat(total_minutes.toFixed(2));
  }

  function Check(questionNos, options) {
    // console.log(questionNos,options)
    for (var singleValue of scoreArray) {
      if (
        singleValue.questionNo === questionNos &&
        singleValue.option === options
      ) {
        // console.log("true")
        return true;
      }
    }

    return false;
  }

  async function changeclass(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    correctAnswer: Array<string>,
    questionId: string,
    option: string,
    marks: number
  ) {
    e.preventDefault();
    setshow(!show);

    if (questions_Attempted.includes(questionId)) {
    } else {
      setQuestionsAttempted((prev) => [...prev, questionId]);
    }

    correctAnswer.forEach((item) => {
      if (item === option) {
        setScoreArray((prev) => {
          const newarray = prev.filter(
            (value) => value.questionId !== questionId
          );

          // logic for created the score object
          return [
            ...newarray,
            {
              questionNo: currQues,
              score: 1 * marks,
              questionId: questionId,
              correct: true,
              option,
            },
          ];
        });

        setQuizProgress((prev) => {
          const newarray = prev.filter(
            (value) => value.questionId !== questionId
          );

          // logic for created the score object
          return [
            ...newarray,
            {
              questionId: questionId,
              selectedAnswer: option,
            },
          ];
        });
      } else {
        setScoreArray((prev) => {
          const newarray = prev.filter(
            (value) => value.questionId !== questionId
          );

          // logic for created the score object
          return [
            ...newarray,
            {
              score: 0,
              questionId: questionId,
              correct: false,
              option,
              questionNo: currQues,
            },
          ];
        });

        setQuizProgress((prev) => {
          const newarray = prev.filter(
            (value) => value.questionId !== questionId
          );

          // logic for created the score object
          return [
            ...newarray,
            {
              questionId: questionId,
              selectedAnswer: option,
            },
          ];
        });
      }
    });
  }

  const addToReview = (_id: string) => {
    if (questions_review.includes(_id)) {
      setQuestionsReview((prev) =>
        prev.filter((value) => {
          return value !== _id;
        })
      );

      return;
    }

    setQuestionsReview((prev) => [...prev, _id]);
  };

  const checkRegex = (ans: string | undefined) => {
    if (!ans) return false;

    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (urlRegex.test(ans)) {
      return true;
    } else {
      return false;
    }
  };

  const submithandler = async () => {
    setSpinner(true);
    localStorage.removeItem("timer" + examId);
    let finalScore = 0;
    for (let i = 0; i < scoreArray.length; i++) {
      finalScore += scoreArray[i].score;
    }
    const submitData = {
      questions_Attempted: questions_Attempted,
      examId: examId,
      score: scoreArray,
      review: questions_review,
      tabsopened: numberofTabs,
      finalScore,
      typeOfExam: typeOfExam.current,
      isCameraOn: isCameraOn,
    };
    await configAxiosLambda(localStorage.getItem("token") || "")
      .post("/makeResult", submitData)
      .then(() => {
        deleteProgress(examId).finally(() => {
          if (displayResult.current) {
            if (textExamExists) {
              window.open(`/quizexamsubmitted/${examId}`, '_blank')
              history.push(`/text-based-exams/${examId}`)
            } else {
              history.push(`/quizexamsubmitted/${examId}`);
            }
          } else {
            if (textExamExists) {
              history.push(`/text-based-exams/${examId}`)
            } else {
              NotificationPopup({
                title: "Success",
                message: "Exam is successfully submitted",
                type: "success",
              });
              history.push("/profile");
            }
          }
        });
      })
      .catch((err) => alert("Unable to submit the exam. Please try again."))
      .finally(() => setSpinner(false));
  };

  const questions = useMemo(
    () => (questionsArray ? questionsArray[index] : undefined),
    [index, questionsArray]
  );
  const length = useMemo(
    () => (questionsArray ? questionsArray.length : 0),
    [questionsArray]
  );

  return (
    <div id="main">
      {displayWebCam && (
        <>
          <HideWebCamStyled>
            <Webcam
              audio={false}
              height={200}
              screenshotFormat="image/jpeg"
              ref={webcamCallback}
              width={300}
              videoConstraints={{
                facingMode: "user",
              }}
              onUserMediaError={() => {
                setIsCamerOn(false);
                alert(
                  "Camera turned off. Your attempt won't be considered. Turn on the camera and refresh the page"
                );
              }}
              onUserMedia={() => {
                setIsCamerOn(true);
              }}
            />
          </HideWebCamStyled>
        </>
      )}
      {displayAlert === true && (
        <div
          className={styles.popup_submission}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className={styles.popup_text}>
              Please select any one option to mark it as review later
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setDisplayAlert(false);
                }}
                className={styles.submission_quiz_button}
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {showpopup === true && (
        <div
          className={styles.popup_submission}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className={styles.popup_text}>
              are you sure that you want to submit your Exam?
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setshowpopup(false);
                }}
                className={styles.submission_quiz_button}
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  submithandler();
                }}
                className={styles.submission_quiz_button}
              >
                {spinner ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        style={{ filter: showpopup || displayAlert ? "blur(10px)" : undefined }}
      >
        {showpopup === true && (
          <div
            className={styles.popup_submission}
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div className="d-flex flex-column">
              <span className={styles.popup_text}>
                Are you sure, you want to submit your Exam?
              </span>
              <div className="d-flex flex-row justify-content-start">
                <button
                  onClick={() => {
                    setshowpopup(false);
                  }}
                  className={styles.submission_quiz_button}
                  style={{
                    background: "transparent",
                    border: "1px solid white",
                    color: "#fff",
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    submithandler();
                  }}
                  className={styles.submission_quiz_button}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
          {token != null ? (
            <>
              <div className={styles.quizPageNavbar}>
                <img src={logo} className={styles.quizPageLogo} alt="logo" />
                <div className={styles.quizPageNavbarRight}>
                  <div className={styles.quizPageProfileDetail}>
                    {/* <img src={""} className={styles.quizPageProfilePic} alt="profile" />
                    <span> {""}</span> */}
                    <img
                      src={userDP}
                      className={styles.quizPageProfilePic}
                      alt="profile"
                    />
                    <span> {userData?.userName || ""}</span>
                  </div>
                  <div className={styles.quizPageTimer}>
                    {hours}:{minutes}:{seconds}
                  </div>
                </div>
              </div>
              <div className={styles.quizQuestionPage}>
                <div className="row gx-lg-5 gx-3">
                  <div className="col-lg-8 col-md-7 mb-0">
                    <div className={`${styles.quizQuestionPageLeft}`}>
                      <div
                        className="row quizQuestionPageLeftContainer"
                        style={{
                          flexGrow: 1,
                          height: "100%",
                          overflowY: "auto",
                        }}
                      >
                        <div
                          className="row mb-0"
                          style={{
                            flexDirection: "column",
                            flexGrow: 1,
                          }}
                        >
                          <div
                            className={`${styles.quizQuestionPageQuestionNum}`}
                          >
                            <span>Question No. {currQues}</span>
                            <span style={{ color: "red" }}>
                              {questions && questions.marks} Mark
                            </span>
                          </div>
                          <hr className="my-2" />

                          <div
                            style={{
                              // marginBottom: "30px",
                              flexGrow: 1,
                              // backgroundColor: "blue",
                              overflow: "auto",
                            }}
                          >
                            <div className={styles.quizQuestionPageQuestion}>
                              {/* <div
                                style={{
                                  // whiteSpace: "pre-wrap",
                                  // maxHeight: "300px",
                                  width: "100%",
                                  // backgroundColor: "yellow",
                                  // overflowY: "scroll",
                                }}
                                className={styles.quiztextContainer}
                                // dangerouslySetInnerHTML={{ __html: questions ? `${questions.question}` : "" }}
                                // dangerouslySetInnerHTML={{
                                //   __html: questions
                                //     ? `Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus quas maxime optio, sint cumque placeat rem asperiores consequuntur enim autem sequi quibusdam eveniet obcaecati tempora natus harum fugit molestias dolores. Nihil, cupiditate. Esse voluptates placeat cupiditate! Totam reprehenderit facere fugiat, autem sint ipsam illo, quo ducimus modi itaque qui perferendis veritatis. Provident vitae harum dolores! Eum asperiores quibusdam quam voluptas, harum architecto fugit libero accusamus ipsam. Necessitatibus consequuntur expedita ratione eaque reprehenderit error est. Ipsam aliquid asperiores vitae reiciendis deserunt libero nisi sed eius voluptatibus, officia earum maxime deleniti officiis accusamus nesciunt possimus ipsa ad et quam? Sequi, eos assumenda.`
                                //     : "",
                                // }}
                              > */}
                              {questions ? `${questions.question}` : ""}

                              {/* </div> */}
                            </div>
                            {questions ? (
                              checkRegex(questions.questionPic) ? (
                                <img
                                  src={questions ? questions.questionPic : ""}
                                  alt="question"
                                  className={`img-fluid ${styles.quizQuestionPageQuestionPic}`}
                                />
                              ) : null
                            ) : null}
                          </div>
                          <div className="mt-auto quiz-questions-options">
                            {questions &&
                              questions.answers.map((option, index) => {
                                return (
                                  <>
                                    {questions.answerSelectionType ===
                                      "single choice" && (
                                        <div
                                          key={index}
                                          className={
                                            Check(currQues, option)
                                              ? styles.quizPageOptionSelcted
                                              : styles.quizPageOption
                                          }
                                          onClick={(e) => {
                                            changeclass(
                                              e,
                                              questions.correctAnswer,
                                              questions._id,
                                              option,
                                              questions.marks
                                            );
                                          }}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            className={styles.quizPageOptionRadio}
                                            type="radio"
                                            value={option}
                                            name="options"
                                            id={index.toString()}
                                            checked={Check(currQues, option)}
                                          />
                                          <label
                                            htmlFor={index.toString()}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {checkRegex(option) ? (
                                              <img
                                                className="img-fluid"
                                                src={option}
                                                alt={option}
                                                style={{
                                                  height: "100px",
                                                }}
                                              />
                                            ) : (
                                              option
                                            )}
                                          </label>
                                        </div>
                                      )}
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-12 d-sm-flex justify-content-between"
                          style={{ marginBottom: "auto" }}
                        >
                          {questions &&
                            questions_Attempted?.includes(questions?._id) ? (
                            <button
                              onClick={() => {
                                addToReview(questions._id);
                              }}
                              className={styles.quizPageRevBtn}
                            >
                              {questions_review.includes(questions._id)
                                ? "Remove from review"
                                : "Review later"}
                            </button>
                          ) : (
                            <button
                              className={`${styles.quizPageRevBtn}`}
                              disabled={false}
                              onClick={() => setDisplayAlert(true)}
                            >
                              Review later
                            </button>
                          )}
                          <div className="rightside">
                            {index === 0 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index === 0) {
                                  } else {
                                    setIndex(index - 1);
                                    setcurrQues((prev) => prev - 1);
                                  }
                                }}
                                className={styles.quizPagePrevBtn}
                              >
                                <i className="fas fa-arrow-left"></i> Prev
                              </button>
                            )}
                            {index === length - 1 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index === length - 1) {
                                  } else {
                                    setIndex(index + 1);
                                    // setselectedId(null);
                                    setcurrQues((prev) => prev + 1);
                                    // setMultipleChoiceArray([]);
                                  }
                                }}
                                className={styles.quizPageNextBtn}
                              >
                                Next <i className="fas fa-arrow-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 mb-0 mt-md-0 mt-sm-3 mt-0">
                    <div className={styles.quizQuestionPageRight}>
                      <div className="content">
                        <div className={styles.quizQuestionPageQuizName}>
                          {examArray.examName}
                        </div>
                        <hr />
                        <ul className={styles.quizPageRightList}>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#E98D15" }}>
                                {unquestions_Attempted.length}
                              </span>
                            </span>
                            Total Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#24B47E" }}>
                                {questions_Attempted.length}
                              </span>
                            </span>
                            Answered Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#3E006E" }}>
                                {questions_review.length}
                              </span>
                            </span>
                            Questions to be Reviewed
                          </li>
                          <li>
                            <span>
                              <span>
                                {unquestions_Attempted.length -
                                  questions_Attempted.length}
                              </span>
                            </span>
                            Un-Answered Questions
                          </li>
                        </ul>
                        <div className={styles.quizQuestionPageQuizName}>
                          All Questions
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap">
                          {questionsArray &&
                            questionsArray.map((item, pos) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      border: "1px solid #BFBFBF",
                                      padding: "3px 2px",
                                      margin: "4px 5px",
                                      borderRadius: "4px",
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                    className={
                                      questions_review.includes(item._id)
                                        ? styles.reviewed
                                        : questions_Attempted.includes(item._id)
                                          ? styles.answered
                                          : questions_Attempted.includes(
                                            item._id
                                          ) === true &&
                                            questions_review.includes(
                                              item._id
                                            ) === true
                                            ? styles.both
                                            : "bg-#133d6a border border-light"
                                    }
                                    onClick={() => {
                                      setIndex(--pos);
                                      setcurrQues(++pos);
                                    }}
                                  >
                                    {++pos}
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className={styles.quizPageExamSubmitBtn}
                          onClick={() => {
                            setshowpopup(true);
                          }}
                        >
                          Submit Exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            history.push("/")
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizzQuestions;
