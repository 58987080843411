/**
 *
 * @param {String} language
 */
export const changeLanguage = (language) => {
  return {
    type: "hybridcompiler/language",
    payload: language,
  };
};

export const changeSolvedIndices = (indices) => {
  return {
    type: "hybridcompiler/solvedIndices",
    payload: indices,
  };
};

/**
 *
 * @param {Array<"C" | "C++" | "Java" | "Python3">} languageValues
 */
export const changeLanguageValues = (languageValues) => {
  return {
    type: "hybridcompiler/languageValues",
    payload: languageValues,
  };
};

export const changeTimer = (timer) => {
  const { hours, seconds, minutes } = timer;

  return {
    type: "hybridExam/timer",
    payload: { hours, seconds, minutes },
  };
};

export const updateQuizData = (data) => {
  return {
    type: "hybridExam/quizExam",
    payload: data,
  };
};

export const updateParagraphData = (data) => {
  return {
    type: "hybridExam/paragraphExam",
    payload: data,
  };
};

/**
 * 
 * @param {{
    examId: string;
    scoring: Array<IScoringArray>;
    totalScore: number;
    noOftabs: number;
    timeTaken: string;
 * }} data
 */
export const updateCodingData = (data) => {
  return {
    type: "hybridExam/codingExam",
    payload: data,
  };
};
