import { useQuery } from "react-query";
import logo from "../../Assets/img/cantilever-logo.png";
import { QUERY_KEY } from "../../constants/querykey";
import { useParams } from "react-router-dom";
import configAxios from "../../axios.config";

const ParagraphInstructions = (props: { onNextClick: () => unknown }) => {
    const { id } = useParams();

    const { data } = useQuery([QUERY_KEY, id], () => (
        configAxios(localStorage.getItem("token")).get(`/getparticularexam-paragraph/${id}`).then(({ data }) => data)
    ))
    return (
        <>
            <div className="d-grid place-content-center"></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-5 instructionPageLeft">
                        <img src={logo} className="instructionPageLogo" alt="logo" />
                        <div className="instructionPageTitle">Paragraph Exam Instructions</div>
                        <div className="row">
                            <div className="col-6">
                                <div className="instructionPageSubTitle">
                                    <i className="fas fa-clock"></i>Test Duration
                                </div>
                                <div className="instructionPageSubText">{data && (data.exam?.ParagraphExam?.timer || 0)} mins</div>
                            </div>
                            <div className="col-6">
                                <div className="instructionPageSubTitle">
                                    <i className="fas fa-file-alt"></i>No. of questions
                                </div>
                                <div className="instructionPageSubText">{data && data.exam?.ParagraphExam?.textBasedQuestions?.length}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 instructionPageRight">
                        <div className="instructionPageRightTitle">Instructions:</div>
                        <ol className="instructionPageRightList">
                            {data && data.exam.ParagraphInstruction.map((value) => {
                                return <li>{value}</li>;
                            })}
                        </ol>
                        <button className="instructionPageBtn" onClick={() => props.onNextClick()}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParagraphInstructions